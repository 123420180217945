.tw-dark .h1,
.tw-dark .h2,
.tw-dark .h3,
.tw-dark .h4,
.tw-dark .h5,
.tw-dark .h6,
.tw-dark ul,
.tw-dark ol,
.tw-dark li,
.tw-dark h1,
.tw-dark h2,
.tw-dark h3,
.tw-dark h4,
.tw-dark h5,
.tw-dark h6 {
  font-family: var(--heading-font-family);
}
.tw-dark .widget_categories .list li a {
  box-shadow: rgba(11, 12, 14, 0.2) 0px 8px 24px;
}
.tw-dark .widget_tags .tag-container {
  box-shadow: rgba(11, 12, 14, 0.2) 0px 8px 24px;
}
.tw-dark .blog-details-desc .article-content ul li,
.tw-dark .blog-details-desc .article-content ol li {
  margin-bottom: 10px;
  color: var(--white-color);
}/*# sourceMappingURL=custom.css.map */